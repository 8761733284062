import Script from 'next/script';
import { useCallback } from 'react';

import {
  RvPageAdsSettings,
  RvPageGoogleAdsSetting,
  RvPageYahooAdsSetting,
  RvPageFacebookAdsSetting,
} from '@/graphqlTypes';

interface AdsTagGeneralProps {
  rvPageCode?: string;
  rvPageAdsSettings?: Omit<RvPageAdsSettings, 'rv_page_id'>;
}

const AdsTagGeneral: React.FC<AdsTagGeneralProps> = function AdsTagGeneral({
  children,
  rvPageCode,
  rvPageAdsSettings,
}) {
  const renderGoogle = useCallback(({ account_id: accountId }: RvPageGoogleAdsSetting) => {
    return accountId ? (
      <>
        <Script
          id="google-ads-general"
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${accountId}`}
        />
        <Script id="google-ads-general-init">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
        
            gtag('config', '${accountId}}');
          `}
        </Script>
      </>
    ) : null;
  }, []);

  const renderYahoo = useCallback(
    ({
      yss_retargeting_is_checked: isYssRetargetingChecked,
      yss_site_retargeting_id: yssRetargetingId,
      yda_retargeting_is_checked: isYdaRetargetingChecked,
      yda_site_retargeting_id: ydaRetargetingId,
    }: RvPageYahooAdsSetting) => {
      return (
        <>
          <Script
            id="yahoo-ads-general"
            async
            src="https://s.yimg.jp/images/listing/tool/cv/ytag.js"
          />
          <Script id="yahoo-ads-general-init">
            {`
              window.yjDataLayer= window.yjDataLayer|| [];
              function ytag() { yjDataLayer.push(arguments);  }

              ytag({"type":"ycl_cookie","config":{"ycl_use_non_cookie_storage":true}});
            `}
          </Script>

          {isYssRetargetingChecked && yssRetargetingId && (
            <Script id="yahoo-yss-retargeting">
              {`
                ytag({
                  "type":"yss_retargeting",
                  "config": {
                    "yahoo_ss_retargeting_id": "${yssRetargetingId}",
                    "yahoo_sstag_custom_params": {
                    }
                  }
                });
              `}
            </Script>
          )}

          {isYdaRetargetingChecked && ydaRetargetingId && (
            <Script id="yahoo-yda-retargeting">
              {`
                ytag({
                  "type":"yjad_retargeting",
                  "config":{
                    "yahoo_retargeting_id": "${ydaRetargetingId}",
                    "yahoo_retargeting_label": "",
                    "yahoo_retargeting_page_type": "",
                    "yahoo_retargeting_items":[
                      {item_id: '', category_id: '', price: '', quantity: ''}
                    ]
                  }
                });
              `}
            </Script>
          )}
        </>
      );
    },
    []
  );

  const renderFacebook = useCallback(({ account_id: accountId }: RvPageFacebookAdsSetting) => {
    return accountId ? (
      <Script id="facebook-ads-general">
        {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${accountId}');
            fbq('track', 'PageView');
          `}
      </Script>
    ) : null;
  }, []);

  return (
    <>
      {rvPageCode !== undefined && rvPageAdsSettings && (
        <>
          {rvPageAdsSettings?.google?.is_published && renderGoogle(rvPageAdsSettings.google)}
          {rvPageAdsSettings?.yahoo?.is_published && renderYahoo(rvPageAdsSettings.yahoo)}
          {rvPageAdsSettings?.facebook?.is_published && renderFacebook(rvPageAdsSettings.facebook)}
        </>
      )}
      {children}
    </>
  );
};

export default AdsTagGeneral;
