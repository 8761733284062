import { Flex, FlexProps, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

interface TenantToolSpinnerProps {
  loadingText?: string;
  flexProps?: FlexProps;
  variant: 'full' | 'section' | 'top' | 'week-status';
  hidden?: boolean;
}

const TenantToolSpinner: React.VFC<TenantToolSpinnerProps> = function TenantToolSpinner({
  loadingText,
  flexProps,
  variant,
  hidden,
}) {
  const styleProp = (() => {
    if (variant === 'section') {
      return {
        h: '50vh',
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
    if (variant === 'full') {
      return {
        h: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
    if (variant === 'top') {
      return {
        h: '10vh',
        justifyContent: 'left',
        alignItems: 'left',
      };
    }
    if (variant === 'week-status') {
      return {
        h: 'auto',
        paddingTop: '25px',
        paddingRight: '15px',
        paddingBottom: '10px',
      };
    }
    return null;
  })();
  return (
    <Flex {...styleProp} {...flexProps} hidden={hidden}>
      <Spinner color="blue.500" mr={2} />
      <Text>{loadingText}</Text>
    </Flex>
  );
};

export default TenantToolSpinner;
