import Cookies from 'js-cookie';

interface CookieInterface {
  accessToken: string;
  refreshToken: string;
}
export default class UserTokenStorage {
  // eslint-disable-next-line no-use-before-define
  private static instance: UserTokenStorage | undefined;

  private static cookieName = 'TenantToolUser';

  private accessToken = '';

  private refreshToken = '';

  public static getInstance(): UserTokenStorage {
    if (!UserTokenStorage.instance) {
      UserTokenStorage.instance = new UserTokenStorage();
    }
    return UserTokenStorage.instance;
  }

  public constructor() {
    const cookie = Cookies.get(UserTokenStorage.cookieName);
    if (cookie) {
      try {
        const js = JSON.parse(cookie) as CookieInterface;
        this.accessToken = js.accessToken;
        this.refreshToken = js.refreshToken;
      } catch (e) {
        //
      }
    }
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public setAccessToken(token: string): void {
    this.accessToken = token;
  }

  public setRefreshToken(token: string): void {
    this.refreshToken = token;
  }

  public save(): void {
    const saveJson: CookieInterface = {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
    };
    Cookies.set(UserTokenStorage.cookieName, JSON.stringify(saveJson), {
      expires: 30,
      path: '/',
      sameSite: 'strict',
      secure: false,
    });
  }

  public removeToken(): void {
    Cookies.remove(UserTokenStorage.cookieName, { path: '/', sameSite: 'strict', secure: false });
    this.accessToken = '';
    this.refreshToken = '';
  }
}
