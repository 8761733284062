import { Box, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';

import usePrevious from '@/hooks/usePrevious';

interface RvPageCodeWatcherProps {
  rvPageCode?: string;
}

const RvPageCodeWatcher: React.FC<RvPageCodeWatcherProps> = function RvPageCodeWatcher({
  children,
  rvPageCode,
}) {
  const prevRvPageCode = usePrevious(rvPageCode);
  useEffect(() => {
    if (prevRvPageCode !== undefined && prevRvPageCode !== rvPageCode) {
      // refreshes the page when there is a change in rvPageCode
      // there's not a documented way in the google docs to "unload" the initialized gtag scripts (ads tags, analytics, etc)
      // so a refresh here ensures we reinitialize the correct script with the correct IDs
      window.location.reload();
    }
  }, [rvPageCode, prevRvPageCode]);

  return prevRvPageCode !== undefined && prevRvPageCode !== rvPageCode ? (
    <>
      <Spinner />
      Loading
    </>
  ) : (
    <Box>{children}</Box>
  );
};

export default RvPageCodeWatcher;
