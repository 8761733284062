import Head from 'next/head';

const TenantToolFavicons: React.VFC = function TenantToolFavicons() {
  return (
    <Head>
      <meta
        name="msapplication-square70x70logo"
        content="/assets/favicons/tenant-tool/site-tile-70x70.png"
      />
      <meta
        name="msapplication-square150x150logo"
        content="/assets/favicons/tenant-tool/site-tile-150x150.png"
      />
      <meta
        name="msapplication-wide310x150logo"
        content="/assets/favicons/tenant-tool/site-tile-310x150.png"
      />
      <meta
        name="msapplication-square310x310logo"
        content="/assets/favicons/tenant-tool/site-tile-310x310.png"
      />
      <meta name="msapplication-TileColor" content="#0078d7" />
      <link
        rel="icon"
        type="image/vnd.microsoft.icon"
        href="/assets/favicons/tenant-tool/favicon.ico"
      />
      <link
        rel="icon"
        type="image/vnd.microsoft.icon"
        href="/assets/favicons/tenant-tool/favicon.ico"
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/assets/favicons/tenant-tool/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/assets/favicons/tenant-tool/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/assets/favicons/tenant-tool/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/assets/favicons/tenant-tool/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/assets/favicons/tenant-tool/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/assets/favicons/tenant-tool/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/assets/favicons/tenant-tool/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/assets/favicons/tenant-tool/apple-touch-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/favicons/tenant-tool/apple-touch-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="36x36"
        href="/assets/favicons/tenant-tool/android-chrome-36x36.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/assets/favicons/tenant-tool/android-chrome-48x48.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="72x72"
        href="/assets/favicons/tenant-tool/android-chrome-72x72.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/assets/favicons/tenant-tool/android-chrome-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="128x128"
        href="/assets/favicons/tenant-tool/android-chrome-128x128.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="144x144"
        href="/assets/favicons/tenant-tool/android-chrome-144x144.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="152x152"
        href="/assets/favicons/tenant-tool/android-chrome-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/assets/favicons/tenant-tool/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="256x256"
        href="/assets/favicons/tenant-tool/android-chrome-256x256.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="384x384"
        href="/assets/favicons/tenant-tool/android-chrome-384x384.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/assets/favicons/tenant-tool/android-chrome-512x512.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="36x36"
        href="/assets/favicons/tenant-tool/icon-36x36.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/assets/favicons/tenant-tool/icon-48x48.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="72x72"
        href="/assets/favicons/tenant-tool/icon-72x72.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/assets/favicons/tenant-tool/icon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="128x128"
        href="/assets/favicons/tenant-tool/icon-128x128.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="144x144"
        href="/assets/favicons/tenant-tool/icon-144x144.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="152x152"
        href="/assets/favicons/tenant-tool/icon-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="160x160"
        href="/assets/favicons/tenant-tool/icon-160x160.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/assets/favicons/tenant-tool/icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="196x196"
        href="/assets/favicons/tenant-tool/icon-196x196.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="256x256"
        href="/assets/favicons/tenant-tool/icon-256x256.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="384x384"
        href="/assets/favicons/tenant-tool/icon-384x384.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/assets/favicons/tenant-tool/icon-512x512.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/favicons/tenant-tool/icon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="24x24"
        href="/assets/favicons/tenant-tool/icon-24x24.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/favicons/tenant-tool/icon-32x32.png"
      />
      <link
        rel="manifest"
        href="/assets/favicons/tenant-tool/manifest.webmanifest"
        crossOrigin="use-credentials"
      />
    </Head>
  );
};

export default TenantToolFavicons;
