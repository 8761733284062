import { DEFAULT_TENANT } from '@/configs/const';
/**
 * Get the subdomain of the passed hostname string.
 * @param hostname
 * @returns string
 */
const hostNameToTenantSubdomain = (hostname: string): string => {
  const defaultTenantSubdomain = String(DEFAULT_TENANT);
  // localhost
  if (hostname.match(/^localhost/)) {
    return defaultTenantSubdomain;
  }

  // ngrok (In localhost)
  if (hostname.match(/ngrok\.io/)) {
    return defaultTenantSubdomain;
  }

  const split = hostname.split('.');
  if (Array.isArray(split) && split.length > 0) {
    return split[0];
  }
  throw new Error('un match sudomain');
};
export default hostNameToTenantSubdomain;
