import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import ApolloClientManager from '@/libs/apolloClient/apolloClientManager';
import { useGetCurrentTenantUserLazyQuery } from '@/tenantTool/graphql/getCurrentTenantUser.generated';
import { Auth, Role } from '@/tenantTool/storage/Auth';

import TenantToolSpinner from '../common/tenantToolSpinner';

const UserProvider: React.FC = function UserProvider({ children }) {
  const auth = Auth.getInstance();
  const [getCurrentTenantUser, { loading, error, data }] = useGetCurrentTenantUserLazyQuery({
    client: ApolloClientManager.get('cognito'),
    pollInterval: 100000,
  });
  const [isLogin, setIsLogin] = useState<boolean>(auth.check());
  const router = useRouter();
  const loginPath = '/tenant-tool';

  useEffect(() => {
    if (!isLogin && !auth.check()) {
      getCurrentTenantUser()
        .then(() => {
          //
        })
        .catch(() => {
          //
        });
    }
  }, [getCurrentTenantUser, isLogin, auth]);

  useEffect(() => {
    if (data && data.getCurrentTenantUser) {
      auth.setUser({
        id: data.getCurrentTenantUser.id || undefined,
        name: data.getCurrentTenantUser.name || undefined,
        role: (data.getCurrentTenantUser.role || null) as Role,
        canAccessTenants: data.getCurrentTenantUser.can_access_tenants || false,
        rvPageIds: (data.getCurrentTenantUser.rv_page_ids as Array<number>) || [],
      });
      setIsLogin(true);
    }
  }, [data, auth]);

  if (error) {
    router
      .push(loginPath)
      .then(() => {
        //
      })
      .catch(() => {
        //
      });
  }

  return (
    <Box className={`role-${String(data?.getCurrentTenantUser?.role?.toLocaleLowerCase())}`}>
      {!isLogin && loading && <TenantToolSpinner loadingText="User Loading..." variant="full" />}
      {error && <TenantToolSpinner loadingText="User Error." variant="full" />}
      {isLogin && children}
    </Box>
  );
};
export default UserProvider;
