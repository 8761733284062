import { Liff } from '@line/liff';
import React, { useEffect, useMemo, useState } from 'react';

import ApolloClientManager from '@/libs/apolloClient/apolloClientManager';
import LiffContext from '@/userTool/contexts/liffContext';

interface Props {
  liffId: string;
}

const LiffProvider: React.FC<Props> = function LiffProvider({ liffId, children }) {
  const [liffObject, setLiffObject] = useState<Liff>();
  const [isLiffInited, setIsLiffInited] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>('');
  const [pictureUrl, setPictureUrl] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');

  useEffect(() => {
    import('@line/liff')
      .then((lf) => {
        const liff = lf as unknown as Liff;
        if (!liffId) {
          return;
        }
        liff
          .init({
            liffId,
          })
          .then(() => {
            setIsLiffInited(true);
            setLiffObject(liff);

            if (!liff.isLoggedIn()) {
              setIsLoggedIn(false);
              return;
            }
            setIsLoggedIn(true);
            setAccessToken(liff.getAccessToken() || '');
            ApolloClientManager.setLineToken(liff.getAccessToken() || '');
            liff
              .getProfile()
              .then((profile) => {
                setDisplayName(profile.displayName);
                setPictureUrl(profile.pictureUrl || '');
              })
              .catch(() => {
                //
              });
          })
          .catch(() => {
            //
          });
      })
      .catch(() => {
        // load error
      });
  }, [liffId]);
  const provide = useMemo(() => {
    return {
      liff: liffObject,
      isLoggedIn,
      displayName,
      pictureUrl,
      accessToken,
      isLiffInited,
    };
  }, [accessToken, displayName, isLiffInited, isLoggedIn, liffObject, pictureUrl]);
  return <LiffContext.Provider value={provide}> {children}</LiffContext.Provider>;
};
export default LiffProvider;
