import * as Types from '../../graphqlTypes.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReservationPageQueryVariables = Types.Exact<{
  rvPageCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetReservationPageQuery = {
  __typename?: 'Query';
  currentTenant?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    sub_domain?: string | null;
    theme_color?: string | null;
    company_name?: string | null;
    liff_id?: string | null;
    tenant_metas?: Array<{
      __typename?: 'TenantMeta';
      key?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
  reservationPage?: {
    __typename?: 'ReservationPage';
    id?: string | null;
    tenant_id?: string | null;
    rv_page_code?: string | null;
    name?: string | null;
    description?: string | null;
    is_published?: number | null;
    approve_reservation?: number | null;
    reservation_type?: number | null;
    is_staff_selectable?: number | null;
    reservation_notice?: string | null;
    reservation_minute_range?: number | null;
    max_duplicates?: number | null;
    deadline_of_reception?: number | null;
    dor_hours_before?: number | null;
    dor_days_before?: number | null;
    dor_time_before?: string | null;
    dor_include_close_day?: boolean | null;
    deadline_of_cancel?: number | null;
    doc_hours_before?: number | null;
    doc_days_before?: number | null;
    doc_time_before?: string | null;
    doc_include_close_day?: boolean | null;
    notification_emails?: Array<string | null> | null;
    is_display_line_friend_button?: number | null;
    calendar_display_mode?: number | null;
    ga_id?: string | null;
    display_order?: number | null;
    coverImage?: {
      __typename?: 'Image';
      id?: string | null;
      path?: string | null;
      url?: string | null;
    } | null;
    rvPageNotificationEmails?: Array<{
      __typename?: 'ReservationPageNotificationEmails';
      id?: string | null;
      email?: string | null;
    } | null> | null;
    integration_setting?: {
      __typename?: 'RvPageIntegrationSetting';
      rv_page_id?: string | null;
      external_id?: string | null;
      api_key?: string | null;
      calendar_start_time?: string | null;
      calendar_end_time?: string | null;
      create_reservation_url?: string | null;
      cancel_reservation_url?: string | null;
      get_reservation_url?: string | null;
      available_slot_url?: string | null;
    } | null;
    integration_error_notification_emails?: Array<{
      __typename?: 'RvPageIntegrationErrorNotificationEmail';
      rv_page_id?: string | null;
      email?: string | null;
    } | null> | null;
    rv_page_meta?: Array<{
      __typename?: 'RvPageMeta';
      rv_page_id?: string | null;
      key?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export const GetReservationPageDocument = gql`
  query getReservationPage($rvPageCode: String) {
    currentTenant {
      id
      name
      sub_domain
      theme_color
      company_name
      liff_id
      tenant_metas {
        key
        value
        type
      }
    }
    reservationPage(rv_page_code: $rvPageCode) {
      id
      tenant_id
      rv_page_code
      name
      description
      is_published
      approve_reservation
      reservation_type
      is_staff_selectable
      reservation_notice
      reservation_minute_range
      max_duplicates
      deadline_of_reception
      dor_hours_before
      dor_days_before
      dor_time_before
      dor_include_close_day
      deadline_of_cancel
      doc_hours_before
      doc_days_before
      doc_time_before
      doc_include_close_day
      notification_emails
      is_display_line_friend_button
      calendar_display_mode
      ga_id
      coverImage {
        id
        path
        url
      }
      display_order
      rvPageNotificationEmails {
        id
        email
      }
      integration_setting {
        rv_page_id
        external_id
        api_key
        calendar_start_time
        calendar_end_time
        create_reservation_url
        cancel_reservation_url
        get_reservation_url
        available_slot_url
      }
      integration_error_notification_emails {
        rv_page_id
        email
      }
      rv_page_meta {
        rv_page_id
        key
        value
        type
      }
    }
  }
`;

/**
 * __useGetReservationPageQuery__
 *
 * To run a query within a React component, call `useGetReservationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationPageQuery({
 *   variables: {
 *      rvPageCode: // value for 'rvPageCode'
 *   },
 * });
 */
export function useGetReservationPageQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReservationPageQuery, GetReservationPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReservationPageQuery, GetReservationPageQueryVariables>(
    GetReservationPageDocument,
    options
  );
}
export function useGetReservationPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReservationPageQuery,
    GetReservationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReservationPageQuery, GetReservationPageQueryVariables>(
    GetReservationPageDocument,
    options
  );
}
export type GetReservationPageQueryHookResult = ReturnType<typeof useGetReservationPageQuery>;
export type GetReservationPageLazyQueryHookResult = ReturnType<
  typeof useGetReservationPageLazyQuery
>;
export type GetReservationPageQueryResult = Apollo.QueryResult<
  GetReservationPageQuery,
  GetReservationPageQueryVariables
>;
