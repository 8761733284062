import Head from 'next/head';

interface GoogleAnalyticsProps {
  gaId?: string;
}

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = function GoogleAnalytics({
  gaId,
  children,
}) {
  return (
    <>
      {gaId !== undefined && (
        <Head>
          <script id="ga" async src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />
          <script
            id="ga-datalayer"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());
            
                gtag('config', '${gaId}');
              `,
            }}
          />
        </Head>
      )}

      {children}
    </>
  );
};

export default GoogleAnalytics;
