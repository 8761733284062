import * as Types from '../../graphqlTypes.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentTenantQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCurrentTenantQuery = {
  __typename?: 'Query';
  currentTenant?: {
    __typename?: 'Tenant';
    id: string;
    name: string;
    sub_domain?: string | null;
    theme_color?: string | null;
    company_name?: string | null;
    liff_id?: string | null;
    has_primary_owner?: boolean | null;
    integration_setting?: {
      __typename?: 'TenantIntegrationSetting';
      is_enabled?: boolean | null;
      setting_location?: number | null;
      calendar_start_time?: string | null;
      calendar_end_time?: string | null;
      create_reservation_url?: string | null;
      cancel_reservation_url?: string | null;
      get_reservation_url?: string | null;
      available_slot_url?: string | null;
      individual_master?: {
        __typename?: 'IndividualMaster';
        id?: string | null;
        individual_name?: string | null;
      } | null;
    } | null;
    integration_error_notification_emails?: Array<{
      __typename?: 'TenantIntegrationErrorNotificationEmail';
      email?: string | null;
    } | null> | null;
  } | null;
};

export const GetCurrentTenantDocument = gql`
  query getCurrentTenant {
    currentTenant {
      id
      name
      sub_domain
      theme_color
      company_name
      liff_id
      has_primary_owner
      integration_setting {
        is_enabled
        setting_location
        calendar_start_time
        calendar_end_time
        create_reservation_url
        cancel_reservation_url
        get_reservation_url
        available_slot_url
        individual_master {
          id
          individual_name
        }
      }
      integration_error_notification_emails {
        email
      }
    }
  }
`;

/**
 * __useGetCurrentTenantQuery__
 *
 * To run a query within a React component, call `useGetCurrentTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentTenantQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentTenantQuery, GetCurrentTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentTenantQuery, GetCurrentTenantQueryVariables>(
    GetCurrentTenantDocument,
    options
  );
}
export function useGetCurrentTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentTenantQuery, GetCurrentTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentTenantQuery, GetCurrentTenantQueryVariables>(
    GetCurrentTenantDocument,
    options
  );
}
export type GetCurrentTenantQueryHookResult = ReturnType<typeof useGetCurrentTenantQuery>;
export type GetCurrentTenantLazyQueryHookResult = ReturnType<typeof useGetCurrentTenantLazyQuery>;
export type GetCurrentTenantQueryResult = Apollo.QueryResult<
  GetCurrentTenantQuery,
  GetCurrentTenantQueryVariables
>;
