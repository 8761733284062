import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { Maybe } from '@/graphqlTypes';
import ApolloClientManager from '@/libs/apolloClient/apolloClientManager';
import ReservationPageContext from '@/tenantTool/contexts/reservationPageContext';
import { useGetReservationPageLazyQuery } from '@/tenantTool/graphql/getReservationPage.generated';

import TenantToolSpinner from '../common/tenantToolSpinner';
import CustomTenant404 from '../customTenant404';

const ReservationPageProvider: React.FC = function ReservationPageProvider({ children }) {
  const router = useRouter();
  const rvPageCode = String(router.query?.reservationPageCode || '');

  const [getReservationPage, { loading, data, error }] = useGetReservationPageLazyQuery({
    client: ApolloClientManager.get('cognito'),
    pollInterval: 10000000,
  });
  useEffect(() => {
    if (rvPageCode === '') {
      return;
    }
    getReservationPage({
      variables: {
        rvPageCode,
      },
    })
      .then(() => {
        //
      })
      .catch(() => {
        //
      });
  }, [rvPageCode, getReservationPage]);

  const [reservationMinuteRange, setReservationMinuteRange] = useState<number>(10);
  const [name, setName] = useState('');
  const [externalId, setExternalId] = useState<Maybe<string>>();

  useEffect(() => {
    if (!data?.reservationPage) {
      return;
    }
    setName(String(data.reservationPage.name));
    setReservationMinuteRange(Number(data.reservationPage.reservation_minute_range || 10));
    setExternalId(data.reservationPage.integration_setting?.external_id);
  }, [data]);

  const value = useMemo(() => {
    if (!data?.reservationPage) {
      return null;
    }

    return {
      id: Number(data.reservationPage.id),
      tenantId: Number(data.reservationPage.tenant_id),
      rvPageCode: String(data.reservationPage.rv_page_code),
      name,
      isPublished: data.reservationPage.is_published === 1,
      setName,
      canUseStaffFunction: data.reservationPage.reservation_type === 1,
      integrationSetting: {
        ...data.reservationPage.integration_setting,
        external_id: externalId,
      },
      setExternalId,
      integrationErrorNotificationEmails:
        data.reservationPage.integration_error_notification_emails || [],
      reservationMinuteRange,
      setReservationMinuteRange: (val: number) => {
        setReservationMinuteRange(val);
      },
    };
  }, [data, reservationMinuteRange, name, externalId]);

  return (
    <>
      {!rvPageCode && <div>{children}</div>}
      {rvPageCode && loading && (
        <TenantToolSpinner loadingText="Reservation Page Loading..." variant="full" />
      )}
      {rvPageCode && error && <CustomTenant404 />}
      {rvPageCode && data && data.reservationPage && value && (
        <ReservationPageContext.Provider value={value}>{children}</ReservationPageContext.Provider>
      )}
    </>
  );
};
export default ReservationPageProvider;
