import React, { Dispatch, SetStateAction } from 'react';

import {
  Maybe,
  RvPageIntegrationSetting,
  RvPageIntegrationErrorNotificationEmail,
} from '@/graphqlTypes';

export type ReservationPageContextProp = {
  id?: number;
  tenantId?: number;
  rvPageCode?: string;
  name?: string;
  isPublished?: boolean;
  canUseStaffFunction?: boolean;
  reservationMinuteRange?: number;
  integrationSetting?: RvPageIntegrationSetting;
  integrationErrorNotificationEmails?: Maybe<RvPageIntegrationErrorNotificationEmail>[];
  setReservationMinuteRange?: (value: number) => void;
  setName?: (value: string) => void;
  setExternalId?: Dispatch<SetStateAction<string | undefined | null>>;
};
const ReservationPageContext = React.createContext<ReservationPageContextProp>({});
export default ReservationPageContext;
