import { useToast as useToastBase, UseToastOptions } from '@chakra-ui/react';

const useToast = (options?: UseToastOptions) => {
  const toast = useToastBase({
    position: 'top-right',
    status: 'success',
    duration: 3000,
    isClosable: true,
    ...options,
  });

  return toast;
};

export default useToast;
