import * as Types from '../../graphqlTypes.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentTenantUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCurrentTenantUserQuery = {
  __typename?: 'Query';
  getCurrentTenantUser?: {
    __typename?: 'TenantUser';
    id?: string | null;
    name?: string | null;
    role?: string | null;
    can_access_admin?: boolean | null;
    can_access_tenants?: boolean | null;
    rv_page_ids?: Array<number | null> | null;
    tenant?: { __typename?: 'Tenant'; name: string } | null;
  } | null;
};

export const GetCurrentTenantUserDocument = gql`
  query getCurrentTenantUser {
    getCurrentTenantUser {
      id
      name
      role
      can_access_admin
      can_access_tenants
      rv_page_ids
      tenant {
        name
      }
    }
  }
`;

/**
 * __useGetCurrentTenantUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentTenantUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentTenantUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentTenantUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentTenantUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentTenantUserQuery,
    GetCurrentTenantUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentTenantUserQuery, GetCurrentTenantUserQueryVariables>(
    GetCurrentTenantUserDocument,
    options
  );
}
export function useGetCurrentTenantUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentTenantUserQuery,
    GetCurrentTenantUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentTenantUserQuery, GetCurrentTenantUserQueryVariables>(
    GetCurrentTenantUserDocument,
    options
  );
}
export type GetCurrentTenantUserQueryHookResult = ReturnType<typeof useGetCurrentTenantUserQuery>;
export type GetCurrentTenantUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentTenantUserLazyQuery
>;
export type GetCurrentTenantUserQueryResult = Apollo.QueryResult<
  GetCurrentTenantUserQuery,
  GetCurrentTenantUserQueryVariables
>;
