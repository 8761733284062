import { useMemo } from 'react';

import { Maybe } from '@/graphqlTypes';
import { ApiIntegrationIndividualMaster } from '@/types';
import UserToolTenantContext from '@/userTool/contexts/UserToolTenantContext';

interface UserToolTenantProviderProps {
  themeColor: string;
  integrationSettings: {
    isEnabled: boolean;
    individualMasterId?: Maybe<string>;
  };
}

const UserToolTenantProvider: React.FC<UserToolTenantProviderProps> =
  function UserToolTenantProvider({
    children,
    integrationSettings: { individualMasterId, isEnabled },
    themeColor,
  }) {
    const providerValue = useMemo(() => {
      return {
        themeColor,
        getIndividualMasterIdentifier: () => {
          const id = individualMasterId ?? 0;

          // Check API-Integrated and return TYPE
          if (isEnabled === true) {
            return Number(id) as ApiIntegrationIndividualMaster;
          }

          return undefined;
        },
      };
    }, [individualMasterId, isEnabled, themeColor]);

    return (
      <UserToolTenantContext.Provider value={providerValue}>
        {children}
      </UserToolTenantContext.Provider>
    );
  };

export default UserToolTenantProvider;
