import React from 'react';

import { TenantContextType } from '@/types';

const TenantContext = React.createContext<TenantContextType>({
  id: '',
  name: '',
  subDomain: '',
  hasData: false,
  hasPrimaryOwner: false,
  integrationSetting: {},
  integrationErrorNotificationEmails: [],
  isUseApi: () => false,
  isIntegrationSettingLocationAll: () => true,
  getIndividualMasterIdentifier: () => undefined,
});
export default TenantContext;
