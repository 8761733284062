import React from 'react';

import { ApiIntegrationIndividualMaster } from '@/types';

const UserToolTenantContext = React.createContext<{
  themeColor: string;
  getIndividualMasterIdentifier: () => ApiIntegrationIndividualMaster;
}>({
  themeColor: 'blue',
  getIndividualMasterIdentifier: () => undefined,
});
export default UserToolTenantContext;
