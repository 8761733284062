import { useEffect, useRef } from 'react';

/**
 * store previous props
 * this would enable comparison of old props vs new props in function components
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
