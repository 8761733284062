var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"sMhc3uq1fEr63rWVp9uRc"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NEXT_PUBLIC_ENV;
const SENTRY_APP = process.env.NEXT_PUBLIC_SENTRY_APP;

Sentry.init({
  dsn: SENTRY_APP === 'enable' ? SENTRY_DSN : undefined,
  environment: ENV,
  tracesSampleRate: 1.0,
});
