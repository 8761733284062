import { Liff } from '@line/liff';
import { createContext } from 'react';

const LiffContext = createContext<{
  liff?: Liff;
  isLoggedIn: boolean; // ログイン状態
  displayName: string; // 表示名
  pictureUrl: string; // 写真アイコン
  accessToken: string; // アクセストークン
  isLiffInited: boolean; // liff initの実行済みか否か
}>({
  isLoggedIn: false,
  isLiffInited: false,
  displayName: '',
  pictureUrl: '',
  accessToken: '',
});
export default LiffContext;
