import { GraphQLErrors } from '@apollo/client/errors';
import { useRouter } from 'next/router';
import { useState, useEffect, useCallback, useMemo } from 'react';

import useToast from '@/hooks/useToast';
import GraphQLErrorsContext from '@/libs/graphql/graphqlErrorsContext';
import { WindowObjectWithGQLErrorSetter } from '@/types';

import matchTenantTool from '../matchTenantTool';

const GraphQLErrorsProvider: React.FC = function GraphQLErrorsProvider({ children }) {
  const [gqlErrors, setGqlErrors] = useState<GraphQLErrors>([]);

  const router = useRouter();
  const isTenantTool = matchTenantTool(router.pathname);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toast = useCallback(useToast(), []);

  useEffect(() => {
    // enables apolloClientManager to invoke state setter
    (window as WindowObjectWithGQLErrorSetter).setGqlErrors = setGqlErrors;
  }, []);

  useEffect(() => {
    if (gqlErrors.length > 0) {
      let errorMessage = '';
      gqlErrors.forEach((error) => {
        errorMessage += `${error.message}\n`;
      });

      // [864dwg0gz] do not display toast in user tool
      if (isTenantTool) {
        toast({
          description: errorMessage,
          status: 'error',
        });
      }
    }
  }, [gqlErrors, toast, isTenantTool]);

  const contextValue = useMemo(() => {
    return { gqlErrors, setGqlErrors };
  }, [gqlErrors, setGqlErrors]);

  return (
    <GraphQLErrorsContext.Provider value={contextValue}>{children}</GraphQLErrorsContext.Provider>
  );
};

export default GraphQLErrorsProvider;
