import Error from 'next/error';
import React, { useMemo } from 'react';

import { useGetCurrentTenantQuery } from '@/libs/graphql/currentTenant.generated';
import { ApiIntegrationIndividualMaster } from '@/types';

import TenantContext from '../../contexts/tenantContext';
import TenantToolSpinner from '../common/tenantToolSpinner';

const TenantProvider: React.FC = function AuthenticationProvider({ children }) {
  const { loading, data, error } = useGetCurrentTenantQuery({
    pollInterval: 100000,
  });

  const value = useMemo(() => {
    if (data?.currentTenant) {
      return {
        id: data?.currentTenant?.id || '',
        name: data?.currentTenant?.name || '',
        subDomain: data?.currentTenant?.sub_domain || '',
        hasData: true,
        hasPrimaryOwner: data.currentTenant.has_primary_owner || false,
        integrationSetting: data.currentTenant.integration_setting || {},
        integrationErrorNotificationEmails:
          data.currentTenant.integration_error_notification_emails || [],
        isUseApi: () => {
          return data.currentTenant?.integration_setting?.is_enabled === true;
        },
        isIntegrationSettingLocationAll: () => {
          return data.currentTenant?.integration_setting?.setting_location === 1;
        },
        getIndividualMasterIdentifier: () => {
          const individualMasterId =
            data.currentTenant?.integration_setting?.individual_master?.id ?? 0;

          // Check if API-Integrated
          if (data.currentTenant?.integration_setting?.is_enabled === true) {
            return Number(individualMasterId) as ApiIntegrationIndividualMaster;
          }

          return undefined;
        },
      };
    }
    return {
      id: '',
      name: '',
      subDomain: '',
      hasData: false,
      hasPrimaryOwner: false,
      integrationSetting: {},
      integrationErrorNotificationEmails: [],
      isUseApi: () => false,
      isIntegrationSettingLocationAll: () => true,
      getIndividualMasterIdentifier: () => undefined,
    };
  }, [data]);

  if (error) {
    return <Error statusCode={403} />;
  }
  return (
    <>
      {loading && <TenantToolSpinner loadingText="Tenant Loading..." variant="full" />}
      {data?.currentTenant && (
        <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
      )}
    </>
  );
};
export default TenantProvider;
