export const defaultLocale: 'ja' | 'en' =
  (process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'en' && 'en') || 'ja';

export const apiToken: string =
  process.env.NEXT_PUBLIC_API_TOKEN ||
  '96a755d02ba2f97e28c72dad278b129487b51c1b6db6318cd2578c475adc05f4';

export const GRAPHQL_PROTOCOL: string = process.env.NEXT_PUBLIC_GRAPHQL_PROTOCOL || 'https';
export const REQUEST_PROTOCOL: string = process.env.NEXT_PUBLIC_GRAPHQL_PROTOCOL || 'http';

export const GRAPHQL_HOST: string =
  process.env.NEXT_PUBLIC_GRAPHQL_HOST || 'micocloud-rs-api.local.host/graphql';

export const GRAPHQL_USER_API_TOKEN: string =
  process.env.NEXT_PUBLIC_GRAPHQL_USER_API_TOKEN ||
  '96a755d02ba2f97e28c72dad278b129487b51c1b6db6318cd2578c475adc05f4';

export const DEFAULT_TENANT: string = process.env.NEXT_PUBLIC_DEFAULT_TENANT || 'tenant1';

export const GLOBAL_TITLE = process.env.NEXT_PUBLIC_GLOBAL_TITLE || 'MC 予約';

export const TENANT_TOOL_TITLE = `${GLOBAL_TITLE} - テナントツール`;

export const APP_IS_TRANSLATABLE: 'yes' | 'no' = process.env.APP_IS_TRANSLATABLE || 'no';

export const DEFAULT_START_TIME = '10:00';
export const DEFAULT_END_TIME = '18:00';
export const RESERVATION_STATUS_CONFIRMED = 5;
