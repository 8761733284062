export type Role = 'PRIMARY_OWNER' | 'OWNER' | 'PAGE_MANAGER' | 'VIEWER' | null;

export interface UserProps {
  id?: string;
  name?: string;
  role?: Role;
  canAccessTenants?: boolean;
  rvPageIds?: Array<number>;
}

export class Auth {
  // eslint-disable-next-line no-use-before-define
  private static instance?: Auth;

  private user?: UserProps;

  private isAuthorize = false;

  public static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  public setUser(user: UserProps): void {
    this.user = user;
    this.isAuthorize = true;
  }

  public getUser(): UserProps {
    if (!this.user) {
      throw new Error('login error');
    }
    return this.user;
  }

  public canOwner(): boolean {
    const roles: Array<Role> = ['PRIMARY_OWNER', 'OWNER'];
    return this.hasRole(roles);
  }

  public canPageManager(): boolean {
    const roles: Array<Role> = ['PRIMARY_OWNER', 'OWNER', 'PAGE_MANAGER'];
    return this.hasRole(roles);
  }

  public isViewer(): boolean {
    return this.user?.role === 'VIEWER';
  }

  private hasRole(roles: Array<Role>): boolean {
    if (!this.user?.role) {
      return false;
    }
    return roles.includes(this.user?.role);
  }

  public check(): boolean {
    return this.isAuthorize;
  }
}
