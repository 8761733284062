import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import { suggestive } from 'yup-locale-ja';
import { LocaleObject } from 'yup/lib/locale';

import { defaultLocale } from '@/configs/const';

import enJson from './locales/en/common.json';
import tenantEnJson from './locales/en/tenantTool.json';
import userEnJson from './locales/en/userTool.json';
import jaJson from './locales/ja/common.json';
import tenantJaJson from './locales/ja/tenantTool.json';
import userJaJson from './locales/ja/userTool.json';

const resources = {
  ja: {
    common: jaJson,
    userTool: userJaJson,
    tenantTool: tenantJaJson,
  },
  en: {
    common: enJson,
    userTool: userEnJson,
    tenantTool: tenantEnJson,
  },
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    lng: defaultLocale,
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((e) => {
    throw e;
  });

if (defaultLocale === 'ja') {
  const jaYup = suggestive as LocaleObject;
  if (jaYup.mixed) {
    // see) node_modules/yup/es/locale.js
    jaYup.mixed.notType = ({ type }) => {
      if (type === 'number') {
        return '数字を入力してください';
      }
      return '正しい形式で入力してください';
    };
  }
  setLocale(jaYup);
}

export default i18n;
