import { Box, Text, Button } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import ApolloClientManager from '@/libs/apolloClient/apolloClientManager';

import { useGetReservationPageLazyQuery } from '../graphql/getReservationPage.generated';
import TenantToolSpinner from './common/tenantToolSpinner';

const CustomTenant404: React.VFC = function CustomTenant404() {
  const router = useRouter();
  const client = ApolloClientManager.get('cognito');

  const [backPage, setBackPage] = useState<string>();

  const getRedirectionPath = useCallback(
    (args: string): string | undefined => {
      const splittedPath = router.asPath.split('/');
      if (Array.isArray(splittedPath) && splittedPath.length > 0 && splittedPath[2] === args) {
        return splittedPath[3];
      }
      return undefined;
    },
    [router]
  );

  const [getReservationPage] = useGetReservationPageLazyQuery({
    client,
    variables: {
      rvPageCode: getRedirectionPath('reservation-page'),
    },
  });

  useEffect(() => {
    const redirectionPath = getRedirectionPath('reservation-page');
    if (redirectionPath) {
      getReservationPage()
        .then((rvp) => {
          if (rvp.data?.reservationPage?.id === undefined) {
            setBackPage('/tenant-tool/home');
          } else {
            setBackPage(`/tenant-tool/reservation-page/${redirectionPath}/home`);
          }
        })
        .catch(() => {
          //
        });
    } else {
      setBackPage('/tenant-tool/home');
    }
  }, [router, getReservationPage, getRedirectionPath]);

  return (
    <Box>
      {backPage === undefined ? (
        <TenantToolSpinner variant="section" />
      ) : (
        <Box align="center" py={20}>
          <Box w="31rem">
            <Text fontSize="2xl">ページが見つかりません</Text>
            <Box py={12} textAlign="left">
              <Text>指定したアドレスにページが見つかりませんでした。ページが削除さ</Text>
              <Text>れたか、アドレスが変更された可能性があります。</Text>
            </Box>
            <Box>
              <Link href={backPage} passHref>
                <Button variant="link" colorScheme="blue" textDecoration="none">
                  戻る
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomTenant404;
