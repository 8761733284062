import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import ja from 'date-fns/locale/ja';
import { AppProps } from 'next/app';
import '@/i18n';
import '@/tenantTool/styles/calendar.scss';
import '@/tenantTool/styles/roleViewerRestriction.scss';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import React from 'react';
import { registerLocale } from 'react-datepicker';

import GoogleAnalytics from '@/components/head/googleAnalytics';
import GoogleTagManager from '@/components/head/googleTagManager';
import TenantToolFavicons from '@/components/head/tenantToolFavicons';
import Title from '@/components/head/title';
import { GLOBAL_TITLE, TENANT_TOOL_TITLE } from '@/configs/const';
import ApolloClientManager from '@/libs/apolloClient/apolloClientManager';
import { GetCurrentTenantQuery } from '@/libs/graphql/currentTenant.generated';
import GraphQLErrorsProvider from '@/libs/graphql/graphqlErrorsProvider';
import matchTenantTool from '@/libs/matchTenantTool';
import ReservationPageProvider from '@/tenantTool/components/providers/reservationPageProvider';
import TenantProvider from '@/tenantTool/components/providers/tenantProvider';
import UserProvider from '@/tenantTool/components/providers/userProvider';
import UserTokenStorage from '@/tenantTool/storage/userTokenStorage';
import { LayoutNextPage } from '@/types';
import AdsTagGeneral from '@/userTool/components/common/adsTags/adsTagsGeneral';
import RvPageCodeWatcher from '@/userTool/components/common/rvPageCodeWatcher';
import { GetIndexPageQuery } from '@/userTool/graphql/pages/index.generated';
import { GetReservationPageCommonQuery } from '@/userTool/graphql/reservationPageCommon.generated';
import LiffProvider from '@/userTool/providers/liffProvider';
import UserToolTenantProvider from '@/userTool/providers/UserToolTenantProvider';

type AppPropsWithLayout = {
  Component: LayoutNextPage;
  pageProps: AppProps & {
    rvPage: GetReservationPageCommonQuery | GetIndexPageQuery | GetCurrentTenantQuery;
  };
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  const router = useRouter();
  const isTenantTool = matchTenantTool(router.pathname);

  const userToken = UserTokenStorage.getInstance();
  ApolloClientManager.setCognitoToken(userToken.getAccessToken());
  const apolloClient = ApolloClientManager.get('api');

  // pageProps (coming from SSR) variables
  // do not rely on these on non-SSR pages
  const ssrRvPage = pageProps?.rvPage as GetReservationPageCommonQuery;
  const ssrGaId = ssrRvPage?.reservationPage?.ga_id || undefined;
  const ssrRvPageCode = ssrRvPage?.reservationPage?.rv_page_code || undefined;
  const ssrRvPageAdsSettings = ssrRvPage?.rvPageAdsSettings || undefined;
  const isUserToolTenantApiEnabled = ssrRvPage?.currentTenant?.integration_setting?.is_enabled;
  const ssrUserToolTenantApiIndivId =
    ssrRvPage?.currentTenant?.integration_setting?.individual_master?.id;

  const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
  };

  const theme = extendTheme({
    styles: {
      global: {
        body: {
          color: 'gray.700',
          '& :focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
            boxShadow: 'none',
          },
          ...(isTenantTool && {
            '& #chakra-toast-manager-top-right': {
              marginTop: '100px',
              marginRight: '30px',
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.chakra-checkbox__control, .chakra-radio__control': {
              backgroundColor: 'white',
            },
          }),
        },
        '.react-datepicker-popper': {
          zIndex: 'calc(var(--chakra-zIndices-modal) + 1)',
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '.react-datepicker__month .react-datepicker__month-text': {
          padding: '0.5rem',
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '.react-datepicker__navigation': {
          top: 0,
        },
      },
    },
    config,
  });

  const getLayout = Component.getLayout ?? ((page) => page);

  // datepicker format
  registerLocale('ja', ja);

  const isDefaultAuth = isTenantTool;
  const authentication = Component.authentication ?? (() => isDefaultAuth);
  const isAuth = authentication();
  const themeColor = String(pageProps?.rvPage?.currentTenant?.theme_color || 'BLUE').toLowerCase();

  return (
    <>
      <GoogleTagManager />
      <Title title={GLOBAL_TITLE} />
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>
          <GraphQLErrorsProvider>
            {isTenantTool && (
              <TenantProvider>
                <Title title={TENANT_TOOL_TITLE} />
                <TenantToolFavicons />
                {(function tenantToolLayoutController() {
                  const ignoreUserProviderRoutes = [
                    '/tenant-tool/password-challenge',
                    '/tenant-tool/password-forget',
                    '/tenant-tool/password-change',
                    '/tenant-tool/',
                  ];
                  if (!ignoreUserProviderRoutes.includes(router.pathname) && isAuth) {
                    return (
                      <UserProvider>
                        <ReservationPageProvider>
                          {getLayout(<Component {...pageProps} />)}
                        </ReservationPageProvider>
                      </UserProvider>
                    );
                  }
                  return getLayout(<Component {...pageProps} />);
                })()}
              </TenantProvider>
            )}

            {!isTenantTool && (
              <>
                <NextNProgress
                  color="white"
                  startPosition={0.3}
                  stopDelayMs={200}
                  height={4}
                  showOnShallow
                />
                <LiffProvider liffId={String(pageProps?.rvPage?.currentTenant?.liff_id || '')}>
                  <RvPageCodeWatcher rvPageCode={ssrRvPageCode}>
                    <GoogleAnalytics gaId={ssrGaId} />
                    <AdsTagGeneral
                      rvPageCode={ssrRvPageCode}
                      rvPageAdsSettings={ssrRvPageAdsSettings}
                    />
                    <UserToolTenantProvider
                      themeColor={themeColor}
                      integrationSettings={{
                        isEnabled: isUserToolTenantApiEnabled === true,
                        individualMasterId: ssrUserToolTenantApiIndivId,
                      }}
                    >
                      {getLayout(<Component {...pageProps} />)}
                    </UserToolTenantProvider>
                  </RvPageCodeWatcher>
                </LiffProvider>
              </>
            )}
          </GraphQLErrorsProvider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
