import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { GetServerSidePropsContext } from 'next';

import { apiToken, GRAPHQL_HOST, GRAPHQL_PROTOCOL } from '@/configs/const';
import { WindowObjectWithGQLErrorSetter } from '@/types';

import hostNameToTenantSubdomain from '../hostNameToTenantSubdomain';

type ClientType = 'cognito' | 'api' | 'line';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    if (typeof window !== 'undefined') {
      (window as WindowObjectWithGQLErrorSetter).setGqlErrors(graphQLErrors);
    } else {
      // handle SSR
    }
  }
});

export default class ApolloClientManager {
  private static apiClients: { [key in ClientType]?: ApolloClient<NormalizedCacheObject> } = {};

  public static subDomain: string;

  private static cognitoToken: string;

  private static lineToken: string | null;

  public static setCognitoToken(token: string): void {
    ApolloClientManager.cognitoToken = token;
  }

  public static setLineToken = (token: string): void => {
    ApolloClientManager.lineToken = token;
    if (ApolloClientManager.apiClients.line) {
      delete ApolloClientManager.apiClients.line;
    }
  };

  private static hasWindow(): boolean {
    return typeof window !== 'undefined';
  }

  public static remove(name: ClientType): void {
    if (ApolloClientManager.apiClients[name]) {
      delete ApolloClientManager.apiClients[name];
    }
  }

  /**
   * The get method only use the window function.
   * @param name
   * @returns
   */
  public static get(name: ClientType): ApolloClient<NormalizedCacheObject> {
    if (ApolloClientManager.hasWindow() && ApolloClientManager.apiClients[name]) {
      return ApolloClientManager.apiClients[name] as ApolloClient<NormalizedCacheObject>;
    }

    const tenantSubDomain = !ApolloClientManager.hasWindow()
      ? ApolloClientManager.subDomain
      : hostNameToTenantSubdomain(window.location.hostname);

    const apiUrl = ApolloClientManager.generateGraphQLUrl(tenantSubDomain);
    const api = ApolloClientManager.makeClient(name, apiUrl);

    if (ApolloClientManager.hasWindow()) {
      ApolloClientManager.apiClients[name] = api;
    }

    return api;
  }

  /**
   * The getFromSSR method can be used instead of get when using Apollo Client from SSR Page. Simply pass the GetServerSidePropsContext as the second Parameter.
   * @param {ClientType} name
   * @param {GetServerSidePropsContext} [SSRContext] - during SSR (getServerSideProps), pass the context here in order to get the appropriate subdomain
   * @returns
   */
  public static getFromSSR(
    name: ClientType,
    SSRContext: GetServerSidePropsContext
  ): ApolloClient<NormalizedCacheObject> {
    if (ApolloClientManager.hasWindow() && ApolloClientManager.apiClients[name]) {
      return ApolloClientManager.apiClients[name] as ApolloClient<NormalizedCacheObject>;
    }

    const forwardedHost = SSRContext.req?.headers['x-forwarded-host'];
    const hostName = forwardedHost ? String(forwardedHost) : String(SSRContext.req?.headers.host);
    if (hostName) {
      ApolloClientManager.subDomain = hostNameToTenantSubdomain(hostName);
    } else {
      throw new Error('hostname cannot be determined from server.');
    }

    return this.get(name);
  }

  /**
   * Generate Apollo Client.
   * @param name
   * @param endpointUrl
   * @returns ApolloClient<NormalizedCacheObject>
   */
  private static makeClient(
    name: ClientType,
    endpointUrl: string
  ): ApolloClient<NormalizedCacheObject> {
    const token = name === 'api' ? apiToken : ApolloClientManager[`${name}Token`];
    const uploadLink = createUploadLink({ uri: endpointUrl });
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${String(token)}`,
            'X-MCRS-TOKEN-TYPE': name === 'cognito' ? 'tenant-user' : `${name}-user`,
          },
        };
      });
      return forward(operation);
    });

    return new ApolloClient({
      ssrMode: true,
      cache: new InMemoryCache(),
      link: from([errorLink, authMiddleware, uploadLink]),
    });
  }

  /**
   * Generate the GraphQL API endpoint url depending
   * on the current client.
   */
  public static generateGraphQLUrl(subDomain: string): string {
    return `${GRAPHQL_PROTOCOL}://${subDomain}.${GRAPHQL_HOST}`;
  }
}
